import { template as template_612c40e0b30042899c046c35852db8ef } from "@ember/template-compiler";
const SidebarSectionMessage = template_612c40e0b30042899c046c35852db8ef(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
