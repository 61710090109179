import { template as template_edc30196d9ef44e9868298e98186d8a7 } from "@ember/template-compiler";
const FKLabel = template_edc30196d9ef44e9868298e98186d8a7(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
