import { template as template_60f9aa0b158f44b99a874818e9235779 } from "@ember/template-compiler";
const WelcomeHeader = template_60f9aa0b158f44b99a874818e9235779(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
